import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ER } from '../types/types';

export enum MethodType {
    GET,
    POST,
}

export interface GenerateURLConfig {
    params?: Record<string, unknown>;
    origin?: string;
    apiKey?: string;
}

export interface RequestConfiguration {
    headers?: HttpHeaders | { [header: string]: string | string[] };
    /**
     * Parameters that'll be included in the request
     */
    params?: {};
    /**
     * Skip parsing of input parameters. Send it as is.
     */
    skipParameterFormatting?: boolean;
    /**
     * Method used for requests
     */
    method?: MethodType;
    /**
     * You can specify a path which'll be extracted from the result
     */
    extractPath?: string | string[];
    /**
     * Used in conjunction with the `extractPath`. This defines a default value in case the extractPath is unavailable or empty.
     */
    defaultValue?: unknown;
    observe?: 'body' | 'response';
    reportProgress?: boolean;
    responseType?: string;
    withCredentials?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class BackendService {
    protected $http = inject(HttpClient);

    public get api() {
        return environment.apiUrl;
    }
    public get upload() {
        return environment.uploadUrl;
    }
    private formUrl(endpoint: string, url = 'api') {
        const host = url === 'api' ? this.api : this.upload;
        return host + '/api/v1' + endpoint;
    }
    public get$<T>(endpoint: string, params?: Record<string, any>, url = 'api') {
        return this.$http.get<T>(this.formUrl(endpoint, url), {
            observe: 'response',
            params,
        }); /* .pipe(catchErrors()) */
    }

    public request$<T = ER.API>(url: string, configuration: RequestConfiguration = {}): Observable<T> {
        let {
            method = MethodType.GET,
            extractPath,
            defaultValue = [],
            headers = {},
            params = {},
            skipParameterFormatting = false,
            ...config
        } = configuration;
        if (
            !(params instanceof HttpParams) &&
            !_.isNil(params) &&
            !_.isEmpty(params) &&
            !skipParameterFormatting
        ) {
            params = this.stringifyParameters(params);
        }
        const httpObservable =
            method === MethodType.GET
                ? this.$http.get(this.formUrl(url), {
                      params,
                      headers,
                      reportProgress: true,
                      ...config,
                  } as Record<string, unknown>)
                : this.$http.post(this.formUrl(url), params, {
                      headers,
                      reportProgress: true,
                      ...config,
                  } as Record<string, unknown>);
        return httpObservable.pipe(
            catchError(() => {
                return of(false);
            }),
            map((data) => {
                if (!_.isNil(extractPath)) {
                    if (_.isArray(extractPath)) {
                        return _.fromPairs(
                            _.map(extractPath, (rawPath: string) => {
                                const path = _.last(_.split(rawPath, '.'));
                                return [path, _.get(data, rawPath)];
                            }),
                        );
                    } else {
                        return _.get(data, extractPath, defaultValue);
                    }
                } else {
                    return data;
                }
            }),
        ) as Observable<T>;
    }

    public post$<T>(endpoint: string, body: Record<string, any>, url = 'api') {
        return this.$http.post<T>(this.formUrl(endpoint, url), body, {
            observe: 'response',
        }); /* .pipe(catchErrors()) */
    }

    public postWithProgress$<T>(endpoint: string, body: Record<string, any>, url = 'api') {
        return this.$http.post<T>(this.formUrl(endpoint, url), body, {
            observe: 'events',
            reportProgress: true,
        }); /* .pipe(catchErrors()) */
    }

    public flattenParams(params: Record<string, any>): {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    } {
        const result: Record<string, unknown> = {};
        for (const [key, value] of Object.entries(params)) {
            if (_.isObject(value) && !_.isArray(value)) {
                const flatObject = this.flattenParams(value);
                for (const [innerKey, innerValue] of Object.entries(flatObject)) {
                    if (_.includes(innerKey, '[')) {
                        const splitKeys = _.split(innerKey, '[');
                        result[key + '[' + _.first(splitKeys) + '][' + _.last(splitKeys)] = innerValue;
                    } else {
                        result[key + '[' + innerKey + ']'] = innerValue;
                    }
                }
            } else {
                result[key] = value;
            }
        }
        return result as {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
    }

    public stringifyHttpParams(params: object): HttpParams {
        return new HttpParams({ fromObject: this.flattenParams(params) });
    }

    public stringifyParameters(params: Record<string, any>): Record<string, any> {
        return _.reduce(
            params,
            (result: Record<string, any>, value, key) => {
                // If the value is undefined then don't include it in the results
                if (_.isUndefined(value)) return result;
                // Check if we are dealing with an object that is not an array
                if (_.isObject(value) && !_.isArray(value)) {
                    result[key] = JSON.stringify(value);
                } else if (_.isArray(value)) {
                    result[key] = _.map(value, (item) =>
                        _.isObject(item) ? this.stringifyParameters(item) : (item as string),
                    );
                } else {
                    result[key] = value + '';
                }
                return result;
            },
            {},
        );
    }
}
